<template>
	<page-header-wrapper>
		<template slot="extra">
			<a-button type="primary" @click="add" v-action:add>新增</a-button>
		</template>
		<a-card :bordered="false">
			<div class="table-page-search-wrapper">
				<a-form layout="inline" v-action:query>
					<a-row :gutter="24">
						<a-col :md="12" :sm="24">
							<div class="tab_main">
								<span class="tab_main_title">筛选：</span>
								<span :class="active==0?'tab_main_act tab_main_block':'tab_main_block'" @click="changeTab(0)">全部</span>
								<span :class="active==1?'tab_main_act tab_main_block':'tab_main_block'" @click="changeTab(1)">未开始</span>
								<span :class="active==2?'tab_main_act tab_main_block':'tab_main_block'" @click="changeTab(2)">进行中</span>
								<span :class="active==3?'tab_main_act tab_main_block':'tab_main_block'" @click="changeTab(3)">已结束</span>
							</div>
						</a-col>

						<a-col :md="8" :sm="24">
							<a-form-item label="关键词">
								<a-input style="" placeholder="请输入活动名称关键词搜索" v-model="queryParam.keyword" />
							</a-form-item>
						</a-col>
						<a-col :md="4" :sm="24">
							<span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
								<a-button style="margin-left: 8px" @click="reset">重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>

			<s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" :scroll="{x:'100%'}"
			 showPagination="auto" class="stable">
				<span slot="content" slot-scope="record">
					<div>
						<div class="content_line1">
							<a-tag color="#e1e1e1" v-if="record.topic_status==1">未开始</a-tag>
							<a-tag color="#87d068" v-else-if="record.topic_status==2">进行中</a-tag>
							<a-tag color="#f50" v-else-if="record.topic_status==3">已结束</a-tag>
							<span class="content_line1_title">{{record.topic}}</span>
						</div>
						<div class="content_line2">
							<span>{{record.start_time|time}} - {{record.end_time|time}}</span>
							<a-divider type="vertical" />
							<span class="content_line2_data"><i>{{record.joinCount}}</i>人参与答题</span>
							<a-divider type="vertical" />
							<span class="content_line2_data">答对题发放<i>{{record.points}}</i>积分</span>
						</div>
					</div>
				</span>
				<span slot="action" slot-scope="text, record">
					<template>
						<a @click="forbid(record,2)" v-if="record.status==1" v-action:setStatus>关闭</a>
						<a @click="forbid(record,1)" v-if="record.status==2" v-action:setStatus>开启</a>
						<a-divider type="vertical" v-action:setStatus />
						<a @click="goJoin(record)" v-action:joinDetail>参与详情</a>
						<a-divider type="vertical" v-action:joinDetail />
						<a @click="rank(record)" v-action:rank>答题排行</a>
						<a-divider type="vertical" v-action:rank />
						<a @click="copy(record)" class="copyBtn" :data-clipboard-text = "'/pages/answer/answer?tid='+record.id" type="text">复制链接</a>
						<a-divider type="vertical" />
						<a @click="goBanks(record)" v-action:bank>题库</a>
						<a-divider type="vertical" v-action:bank />
						<a @click="handleEdit(record)" v-action:edit>编辑</a>
						<a-divider type="vertical" v-action:edit />
						<a @click="del(record)" v-action:del>删除</a>
					</template>
				</span>
			</s-table>
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import moment from 'moment'
	import {
		Modal,
		message
	} from 'ant-design-vue'
	import {
		STable,
		Ellipsis
	} from '@/components'
	import {
		getTransferJmhList,
		getTransferInfoJmh,
		handleJmh
	} from '@/api/service'
	import Clipboard from 'clipboard'
	import {
		prefix,
		getSource
	} from '@/utils'
	import {
		getList,
		_del,
		_forbid
	} from '@/api/answer.js'
	const columns = [{
			title: '标题',
			scopedSlots: {
				customRender: 'content'
			},
			width: '58%',
			align: 'left'
		},
		{
			title: '操作',
			dataIndex: 'action',
			width: '42%',
			align: 'right',
			scopedSlots: {
				customRender: 'action'
			}
		}
	]

	export default {
		name: '',
		components: {
			STable,
			Ellipsis
		},
		data() {
			this.columns = columns
			return {
				// create model
				visible: false,
				advanced: false,
				// 查询参数
				queryParam: {
					keyword: '',
					topic_status:0
				},
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					//console.log('parameter',parameter)
					const requestParameters = Object.assign({}, parameter, this.queryParam)
					//console.log('requestParameters',requestParameters)
					return getList(requestParameters)
						.then(res => {
							return res.data
						})
				},
				photo: [],
				modalVisible: false, //回复弹窗
				reason: '', //审核理由
				info: '', //详情
				id: '', //列表id
				status: '', //2 通过  3 拒绝
				isChecked: false,
				prefix: prefix,
				active: 0, //全部
			}
		},
		methods: {
			getSource: getSource,
			changeTab(tab) {
				this.active = tab
				this.queryParam.topic_status = tab
				this.$refs.table.refresh(true)
			},
			copy(data){
				let that=this;
				let clipboard = new Clipboard('.copyBtn');
				clipboard.on('success', e => {  
					that.$message.success('复制成功');
					clipboard.destroy()  
				})  
				clipboard.on('error', e => {  
					that.$message.success('该浏览器不支持自动复制');  
					clipboard.destroy()  
				})  
			},
			goJoin(data){
				this.$router.push({path:'/operateManage/joinList?id='+data.id})
			},
			rank(data){
				this.$router.push({path:'/operateManage/rank?id='+data.id})
			},
			reply() { //回复弹窗打开
				this.modalVisible = true
			},
			del(data) { //删除
				let that = this
				let params = {
					tid:data.id
				}
				Modal.confirm({
					content:'确认删除吗?' ,
					onOk() {
						_del(params).then(res => {
							if(res.code==200){
								message.success('删除成功')
								that.$refs.table.refresh(true)
							}else{
								message.success(res.msg)
							}
						})
					},
					onCancel() {
						
					}
				})
			},
			forbid(data,status){
				let params = {
					id:data.id,
					status:status
				}
				_forbid(params).then(res =>{
					if(res.code==200){
						this.$message.success(res.msg)
						this.$refs.table.refresh(true)
					}else{
						this.$message.success(res.msg)
					}
				}).catch(res =>{
					
				})
			},
			reset() { //重置
				this.queryParam.keyword = ''
				this.queryParam.topic_status = '0'
				this.$refs.table.refresh(true)
			},
			goBanks(record) { //题库
				this.$router.push({
					path: '/operateManage/questionBank?id=' + record.id
				})
			},
			handleEdit(record) { //编辑
				this.$router.push({
					path: '/operateManage/editAnswer?id=' + record.id
				})
			},
			add() { //新增
				this.$router.push({
					path: '/operateManage/editAnswer?id='
				})
			},
			handleWatch(record) {
				this.id = record.id
				this.getDetail(record.id)
				this.isChecked = true;
				this.visible = true
			},
			handlePre(record) { //预览
				let url = 'http://gssource.joyimedia.com/h5/transfer_personal.html?id=' + record.id
				window.open(url);
			},
			getDetail(id) { //详情
				let params = {
					id: id
				}
				getTransferInfoJmh(params).then(res => {
					//console.log('res',res)
					this.info = res.data;
					var arr = Object.entries(res.data.attachment);
					console.log(arr)
					this.photo = arr;
					this.$previewRefresh()
				})
			},
			onClose(status) { //关闭右侧弹出层   2 通过  3 拒绝
				console.log('status', status)
				this.status = status;
				if (status == 2 || status == 3) {
					this.modalVisible = true;
				} else {
					this.visible = false
				}
			}
		},
		filters: {
			time(t) {
				if (t) {
					return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:mm')
				} else {
					return t
				}
			},
			statusFilter(type) {
				return statusMap[type].text
			},
			statusTypeFilter(type) {
				return statusMap[type].status
			}
		}
	}
</script>


<style scoped>
	.tab_main {}

	.tab_main_title {
		margin-right: 10px;
	}

	.tab_main_block {
		margin-right: 10px;
		cursor: pointer;
	}

	.tab_main_act {
		color: #1890ff;
	}

	.content_line1 {
		margin-bottom: 15px;
	}

	.content_line1_title {
		font-weight: 600;
		font-size: 16px;
		color: #000;
	}

	.content_line2 {}

	.content_line2_data {}

	.content_line2_data i {
		font-style: normal;
		color: #f50;
		margin: 0 5px;
	}
</style>
